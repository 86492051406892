import { SessionProvider } from "next-auth/react";

import ConfirmationProvider from "./ConfirmationProvider";
import ReactQueryClientProvider from "./ReactQueryClientProvider";
import SnackbarProvider from "./SnackbarProvider";
import MuiProvider from "./MuiProvider";

const AppProviders = ({ children, session, dehydratedState, emotionCache }) => {
  return (
    <SessionProvider session={session}>
      <MuiProvider value={emotionCache}>
        <ConfirmationProvider>
          <SnackbarProvider>
            <ReactQueryClientProvider dehydratedState={dehydratedState}>
              {children}
            </ReactQueryClientProvider>
          </SnackbarProvider>
        </ConfirmationProvider>
      </MuiProvider>
    </SessionProvider>
  );
};

export default AppProviders;
