import { useDebugValue, useEffect, useState } from "react";
import parse from "./parse";

const useLocalStorage = (key, initialState) => {
  const [state, setState] = useState(initialState);
  useDebugValue(state);

  useEffect(() => {
    const item = localStorage.getItem(key);
    if (item) setState(parse(item));
  }, [key]);

  const setValue = (value) => {
    setState(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [state, setValue];
};

export default useLocalStorage;
