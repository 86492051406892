import React from "react";
import Link from "@/common/components/elements/Link";

const HeaderLink = ({ href, children, ...restProps }) => {
  return (
    <Link href={href} color="textPrimary" variant="body1" underline="none" {...restProps}>
      {children}
    </Link>
  );
};

export default HeaderLink;
