import routeTo from "../utils/routeTo";
import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import usePlaybook from "../character/usePlaybook";

const routes = {
  movesInPlaybook: (characterId) => routeTo(...usePlaybook.routes.playbook(characterId), "moves"),
  move: (id) => routeTo("playbooks", "moves", id),
};

const keys = {
  movesInPlaybook: (characterId) => buildKey(...usePlaybook.keys.playbook(characterId), "moves"),
  move: (id) => buildKey("playbooks", "moves", id),
};

const actions = {
  update: (moveId, options) =>
    useRest.update({
      url: routes.move(moveId),
      key: keys.move(moveId),
      options,
    }),
};

const usePlaybookMove = {
  routes,
  keys,
  ...actions,
};

export default usePlaybookMove;
