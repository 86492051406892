import React from "react";
import Box from "@mui/material/Box";

const FlexBox = ({ children, alignItems = "center", ...restProps }) => {
  return (
    <Box display="flex" alignItems={alignItems} {...restProps}>
      {children}
    </Box>
  );
};

export default FlexBox;
