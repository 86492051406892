import { useQuery } from "@tanstack/react-query";
import { localAxiosClient } from "@/common/utils/axiosClient";
import { addErrorDetails } from "./utils";

/**
 * Wrapper for the useQuery hook to send +get+ requests to an api
 *
 * @param   {string}  url       The API endpoint to query
 * @param   {string}  key       The key to use to cache the response's data
 * @param   {object}  options   Options
 *
 * @return  {object}            @see https://react-query.tanstack.com/guides/queries
 */
const useFetch = ({ url, key, options = {} }) => {
  const { onUnauthorized, onNotFound, pageResource = false, ...restOptions } = options;

  const queryOptions = {
    useErrorBoundary: true,
    staleTime: 1000 * 60 * 1, // 1 minute
    ...restOptions,
  };

  /**
   * Perform the api request and handle any errors
   *
   * @return  {Object}  Data returned by api
   */
  const doQuery = async () => {
    try {
      const result = await localAxiosClient.get(url);
      return result.data;
    } catch (error) {
      let fullError = addErrorDetails(error);
      fullError.errorPageType = pageResource ? fullError.statusCode : 500;
      if (fullError.statusCode === 403 && onUnauthorized) return onUnauthorized();
      if (fullError.statusCode === 404 && onNotFound) return onNotFound();
      throw fullError;
    }
  };

  /**
   * Wrap the React-Query useQuery hook and return it
   */
  return useQuery({
    queryKey: key,
    queryFn: doQuery,
    options: queryOptions,
  });
};

export default useFetch;
