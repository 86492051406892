import React from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import BurgerMenuButton from "./components/BurgerMenuButton";
import Toolbar from "./components/Toolbar";
import Spacer from "./components/Spacer";
import ProfileMenuButton from "./components/ProfileMenuButton";
import DesktopNav from "./components/DesktopNav";

const menuItems = [
  ["Campaigns", "/"],
  ["Codex", "/codex"],
  ["Feedback", "https://docs.google.com/forms/d/1M4wll5Qe5paUDDzwcFQmK-lsfwor3BR16CffaI848T8"],
];

export const AppBar = () => {
  const theme = useTheme();
  const isWideMode = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Toolbar>
        {!isWideMode && <BurgerMenuButton menuItems={menuItems} />}
        <Typography variant="h5" noWrap>
          <b>DW</b>Beyond
        </Typography>
        {isWideMode && <DesktopNav menuItems={menuItems} />}
        <Spacer />
        <ProfileMenuButton />
      </Toolbar>
    </>
  );
};

export default AppBar;
