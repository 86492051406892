import { useState, useEffect } from "react";

import { useLocalStorage, useSessionStorage } from "@/common/hooks/useNextStorage";

const useBetaAlertState = () => {
  const [seenBetaAlert, setSeenBetaAlert] = useSessionStorage("seenBetaAlert", false);
  const [suppressBetaAlert, setSuppressBetaAlert] = useLocalStorage("neverBetaAlert", false);
  const [showModal, setShowModal] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  useEffect(() => {
    // If haven't rendered yet, do nothing
    if (!hasRendered) return;

    // If we've seen or suppressed the beta alert, do nothing
    if (seenBetaAlert || suppressBetaAlert) return;

    setShowModal(true);
  }, [hasRendered, seenBetaAlert, suppressBetaAlert]);

  const handleClose = (_event, reason) => {
    if (!reason || reason !== "backdropClick") {
      setSeenBetaAlert(true);
      setShowModal(false);
    }
  };

  const handleSuppress = () => {
    setSuppressBetaAlert(true);
  };

  return {
    showModal,
    handleClose,
    handleSuppress,
  };
};

export default useBetaAlertState;
