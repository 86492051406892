import { signOut } from "next-auth/react";

import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Identicon from "@/common/components/elements/Identicon";
import Link from "@/common/components/elements/Link";
import { useProfile } from "@/common/hooks/useApi";

const ProfileMenu = ({ anchorEl, menuId, isMenuOpen, onClose }) => {
  const { data: user } = useProfile.fetch();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={onClose}
      onClick={onClose}
    >
      <MenuItem component={Link} href="/501">
        <ListItemIcon>
          <Identicon seed={user?.username} size={24} />
        </ListItemIcon>
        Profile
      </MenuItem>

      <MenuItem onClick={() => signOut()}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
