import useRest from "../useRest";
import routeTo from "../utils/routeTo";
import buildKey from "../utils/buildKey";
import defaultOptions from "./defaultOptions";
import buildPrefetch from "../utils/buildPrefetch";

// Endpoints
const routes = {
  characterClasses: () => routeTo("codex", "character_classes"),
  characterClass: (id) => routeTo("codex", "character_classes", id),
};

// Cache Keys
const keys = {
  characterClasses: () => buildKey("codex", "character_classes"),
  characterClass: (id) => buildKey("codex", "character_classes", id),
};

// Prefetch queries
const prefetch = (queryClient) => ({
  list: (options) =>
    buildPrefetch({
      queryClient,
      url: routes.characterClasses(),
      key: keys.characterClasses(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    buildPrefetch({
      queryClient,
      url: routes.characterClass(id),
      key: keys.characterClass(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
});

// Resource CRUD actions
const actions = {
  list: (options) =>
    useRest.fetch({
      url: routes.characterClasses(),
      key: keys.characterClasses(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    useRest.fetch({
      url: routes.characterClass(id),
      key: keys.characterClass(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
};

/**
 * Assembel export
 */
const useCodexCharacterClass = {
  routes,
  keys,
  prefetch,
  ...actions,
};

export default useCodexCharacterClass;
