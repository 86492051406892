import routeTo from "../utils/routeTo";
import useRest from "../useRest";
import buildKey from "../utils/buildKey";

const routes = {
  profile: () => routeTo("profile"),
};

const keys = {
  profile: () => buildKey("profile"),
};

const actions = {
  fetch: (options) =>
    useRest.fetch({
      url: routes.profile(),
      key: keys.profile(),
      options,
    }),
};

const useProfile = {
  routes,
  keys,
  ...actions,
};

export default useProfile;
