import useRest from "../useRest";
import routeTo from "../utils/routeTo";
import buildKey from "../utils/buildKey";
import defaultOptions from "./defaultOptions";
import buildPrefetch from "../utils/buildPrefetch";

// Endpoints
const routes = {
  gearCategories: () => routeTo("codex", "gear_categories"),
  gearCategory: (id) => routeTo("codex", "gear_categories", id),
};

// Cache Keys
const keys = {
  gearCategories: () => buildKey("codex", "gear_categories"),
  gearCategory: (id) => buildKey("codex", "gear_categories", id),
};

// Prefetch resources
const prefetch = (queryClient) => ({
  list: (options) =>
    buildPrefetch({
      queryClient,
      url: routes.gearCategories(),
      key: keys.gearCategories(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    buildPrefetch({
      queryClient,
      url: routes.gearCategory(id),
      key: keys.gearCategory(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
});

// Resource Actions
const actions = {
  list: (options) =>
    useRest.fetch({
      url: routes.gearCategories(),
      key: keys.gearCategories(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    useRest.fetch({
      url: routes.gearCategory(id),
      key: keys.gearCategory(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
};

/**
 * Assemble export
 */
const useCodexGearCategory = {
  routes,
  keys,
  prefetch,
  ...actions,
};

export default useCodexGearCategory;
