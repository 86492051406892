import routeTo from "../utils/routeTo";
import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import useCharacter from "./useCharacter";

const routes = {
  playbook: (characterId) => routeTo(useCharacter.routes.character(characterId), "playbook"),
};

const keys = {
  playbook: (characterId) => buildKey(...useCharacter.routes.character(characterId), "playbook"),
};

const actions = {
  update: (characterId, options) =>
    useRest.update({
      url: routes.playbook(characterId),
      key: keys.playbook(characterId),
      options,
    }),
};

const usePlaybook = {
  routes,
  keys,
  ...actions,
};

export default usePlaybook;
