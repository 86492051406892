import Head from "next/head";
import CssBaseline from "@mui/material/CssBaseline";

import ErrorBoundary from "@/_app/components/ErrorBoundary";
import AppProviders from "@/_app/providers";
import AppLayout from "@/layouts/AppLayout";
import ErrorHero from "@/common/components/molecules/ErrorHero";
import { startBugsnag } from "@/common/utils/bugsnag";

startBugsnag();

export default function MyApp({ Component, pageProps, emotionCache }) {
  const { session, dehydratedState, errorCode, ...restPageProps } = pageProps;

  const getLayout = Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AppProviders session={session} dehydratedState={dehydratedState} emotionCache={emotionCache}>
        <ErrorBoundary>
          <CssBaseline enableColorScheme />
          {!errorCode
            ? getLayout(<Component {...restPageProps} />)
            : getLayout(<ErrorHero variant={errorCode} />)}
        </ErrorBoundary>
      </AppProviders>
    </>
  );
}
