import Link from "@/common/components/elements/Link";
import BaseErrorHero from "./BaseErrorHero";

const NotImplementedHero = () => {
  return (
    <BaseErrorHero
      hero="coming soon"
      description={
        <>
          This Page/Feature has not yet been implemented. See the{" "}
          <Link href="/beta">Beta Info</Link> page for more information.
        </>
      }
    />
  );
};

export default NotImplementedHero;
