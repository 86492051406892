import { useMutation } from '@tanstack/react-query';
import axios from "axios";

/**
 * Wrapper for useMutation hooks to send +create+ requests to an api endpoint
 *
 * @param   {string}  url  The API endpoint to mutate against
 *
 * @return  {object}            @see https://react-query.tanstack.com/guides/mutations
 */
const useAuth = ({ url, options = {} }) => {
  const doMutation = async (payload) => {
    try {
      const result = await axios.post(url, payload);
      return {
        data: result.data,
        status: result.status,
        errors: result.data?.errors,
      };
    } catch (error) {
      return {
        status: error?.response?.status || 500,
        errors: error?.response?.data?.errors || {},
      };
    }
  };

  /**
   * Wrap the React-Query useMutation hook and return it
   */
  return useMutation(doMutation, options);
};

export default useAuth;
