import useRest from "../useRest";
import routeTo from "../utils/routeTo";
import buildKey from "../utils/buildKey";
import buildPrefetch from "../utils/buildPrefetch";
import useCampaign from "../campaign/useCampaign";

const routes = {
  charactersInCampaign: (campaignId) =>
    routeTo(useCampaign.routes.campaign(campaignId), "characters"),
  character: (id) => routeTo("characters", id),
};

const keys = {
  charactersInCampaign: (campaignId) =>
    buildKey(...useCampaign.keys.campaign(campaignId), "characters"),
  character: (id) => buildKey("characters", id),
};

const prefetch = (queryClient) => ({
  fetch: (id, options) =>
    buildPrefetch({
      queryClient,
      url: routes.character(id),
      key: keys.character(id),
      options,
    }),
});

const actions = {
  listInCampaign: (campaignId, options) =>
    useRest.fetch({
      url: routes.charactersInCampaign(campaignId),
      key: keys.charactersInCampaign(campaignId),
      options,
    }),

  fetch: (id, options) =>
    useRest.fetch({
      url: routes.character(id),
      key: keys.character(id),
      options,
    }),

  createInCampaign: (campaignId, options) =>
    useRest.create({
      url: routes.charactersInCampaign(campaignId),
      key: keys.charactersInCampaign(campaignId),
      options,
    }),

  update: (id, options) =>
    useRest.update({
      url: routes.character(id),
      key: keys.character(id),
      options,
    }),

  delete: (id, options) =>
    useRest.fetch({
      url: routes.character(id),
      key: keys.character(id),
      options,
    }),
};

const useCharacter = {
  routes,
  keys,
  prefetch,
  ...actions,
};

export default useCharacter;
