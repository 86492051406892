import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";

import createEmotionCache from "@/_app/styles/createEmotionCache";
import muiTheme from "@/_app/styles/muiTheme";

const clientSideEmotionCache = createEmotionCache();

const MuiProvider = ({ children, emotionCache = clientSideEmotionCache }) => {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export default MuiProvider;
