import { useMutation, useQueryClient } from "@tanstack/react-query";
import { localAxiosClient } from "@/common/utils/axiosClient";
import { addErrorDetails } from "./utils";

/**
 * Wrapper for useMutation hooks to send +create+ requests to an api endpoint
 *
 * If the mutation is successful and an +cacheKey+ is provided, the cache will
 * be optimistically updated.
 *
 * @param   {string}  url  The API endpoint to mutate against
 * @param   {string}  key  (optional) The cacheKey to update on mutation success.
 *
 * @return  {object}            @see https://react-query.tanstack.com/guides/mutations
 */
const useCreate = ({ url, key, options = {} }) => {
  const queryClient = useQueryClient();

  const doMutation = async (payload) => {
    try {
      const result = await localAxiosClient.post(url, payload);
      return {
        data: result.data,
        status: result.status,
        errors: result.data?.errors,
      };
    } catch (error) {
      throw addErrorDetails(error);
    }
  };

  const onSuccess = (object) => {
    // Update cache with new object
    if (object.status === 200 && !!key) {
      let collectionData = queryClient.getQueryData({ queryKey: key });
      if (collectionData) {
        // There was already data for this collection; add the new item
        collectionData.push(object.data);
        queryClient.setQueryData(key, collectionData);
      } else {
        // There was no preexisting cached data; create it with the new item
        queryClient.setQueryData(key, [object.data]);
      }
    }

    // Call onSuccess if it's passed in the options
    if (options.onSuccess) options.onSuccess(object);
  };

  /**
   * Wrap the React-Query useMutation hook and return it
   */
  return useMutation({
    mutationFn: doMutation,
    ...options,
    onSuccess,
  });
};

export default useCreate;
