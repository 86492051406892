import useRest from "../useRest";
import routeTo from "../utils/routeTo";
import buildKey from "../utils/buildKey";
import defaultOptions from "./defaultOptions";
import buildPrefetch from "../utils/buildPrefetch";

// Endpoints
const routes = {
  archtypes: () => routeTo("codex", "archtypes"),
  archtype: (id) => routeTo("codex", "archtypes", id),
};

// Cache Keys
const keys = {
  archtypes: () => buildKey("codex", "archtypes"),
  archtype: (id) => buildKey("codex", "archtypes", id),
};

// Prefetch resources
const prefetch = (queryClient) => ({
  list: (options) =>
    buildPrefetch({
      queryClient,
      url: routes.archtypes(),
      key: keys.archtypes(),
      options,
    }),
});

// Resource CRUD actions
const actions = {
  list: (options) =>
    useRest.fetch({
      url: routes.archtypes(),
      key: keys.archtypes(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    useRest.fetch({
      url: routes.archtype(id),
      key: keys.archtype(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
};

/**
 * Assembel export
 */
const useCodexArchtype = {
  routes,
  keys,
  prefetch,
  ...actions,
};

export default useCodexArchtype;
