import * as React from "react";
import Link from "@/common/components/elements/Link";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WarningIcon from "@mui/icons-material/Warning";

import { signOut } from "next-auth/react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  height: "2.5rem",
  justifyContent: "flex-end",
}));

const BurgerMenu = ({ isOpen, onClose }) => {
  return (
    <Drawer open={isOpen} onClose={onClose}>
      <Box sx={{ width: 250 }} role="presentation" onClick={onClose} onKeyDown={onClose}>
        <DrawerHeader>
          <IconButton onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem button component={Link} href="/">
            <ListItemText primary="Campaigns" />
          </ListItem>
          <ListItem button component={Link} href="/codex">
            <ListItemText primary="Codex" />
          </ListItem>
          <ListItem
            button
            component={Link}
            href="https://docs.google.com/forms/d/1M4wll5Qe5paUDDzwcFQmK-lsfwor3BR16CffaI848T8"
          >
            <ListItemText primary="Feedback" />
          </ListItem>
          <ListItem button component={Link} href="/beta">
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText primary="Beta Info" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} href="/501">
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={() => signOut()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default BurgerMenu;
