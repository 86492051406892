import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import useOpenState from "@/common/hooks/useOpenState";
import BurgerMenu from "./BurgerMenu";

export const BurgerMenuButton = () => {
  const { isOpen, handleOpen, handleClose } = useOpenState();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleOpen}
        edge="start"
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>

      <BurgerMenu isOpen={isOpen} onClose={handleClose} />
    </>
  );
};

export default BurgerMenuButton;
